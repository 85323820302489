import React, { ReactElement } from 'react';
import App from './App';

export function provider() {
  return {
    jupiter_submodule_app_key(props: any): ReactElement {
      return <App {...props} />;
    }
  };
}
